<template>
    <div class="dict">
        <el-page-header
            style="margin-bottom: 1.04167vw"
            @back="goBack"
            :content="'运营列表/审批'"
        >
            <template slot="title">
                <i class="el-icon-arrow-left"></i>返回
            </template>
        </el-page-header>
        <el-form 
            ref="ruleForm"
            style="width: 80%"
            :model="form"
            :rules="role"
            :label-width="labelWidth"
        >
            <el-form-item label="园校：" prop="">
                <el-input type="text" disabled v-model="detailObj.campusName" ></el-input>
            </el-form-item>
            <el-form-item label="岗位：" prop="">
                <el-input type="text" disabled v-model="detailObj.jobId" ></el-input>
            </el-form-item>
            <el-form-item label="姓名：" prop="">
                <el-input type="text" disabled v-model="detailObj.userName" ></el-input>
            </el-form-item>
            <el-form-item label="任务名称：" prop="">
                <el-input type="text" disabled v-model="detailObj.itemName" ></el-input>
            </el-form-item>
            <el-form-item label="截止日期：" prop="">
                <el-input type="text" disabled v-model="detailObj.endTime" ></el-input>
            </el-form-item>
            <el-form-item label="逾期时长：" prop="">
                <el-col :span="23">
                    <el-input type="text" disabled v-model="detailObj.overdue" ></el-input>
                </el-col>
                <el-col :span="1">
                    <span class="days">天</span>
                </el-col>
            </el-form-item>
            <el-form-item label="逾期原因：" prop="">
                <el-input type="textarea" disabled v-model="detailObj.timeoutReason" :rows="4"></el-input>
            </el-form-item>
            <!-- 审批意见 -->
            <el-form-item label="审批意见：" prop="againCancel">
                <el-radio-group v-model="form.againCancel">
                    <el-radio :label="item.value" v-for="(item,index) in againCancelList" :key="index">{{item.name}}</el-radio>
                    <!-- <el-radio :label="6">废弃</el-radio> -->
                </el-radio-group>
            </el-form-item>
            <el-form-item v-if="form.againCancel == 1" label="重办截止时间：" prop="reEndTime">
                <el-date-picker
                    v-model="form.reEndTime"
                    type="date"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择日期">
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="备注：" prop="">
                <el-input type="textarea" placeholder="请输入备注" :maxlength="500" v-model="form.commit" :rows="4"></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-button @click="goBack" class="reset">取消</el-button>
                <el-button type="primary" :loading="loading" :disabled="loading" @click="submitBtn">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    data(){
        return{
            pickerOptions: {
                disabledDate(time) {
                let _now = Date.now();
                    return time.getTime() < _now;
                }
            },
            form:{
                againCancel:'',
                reEndTime:'',
                commit:'',
            },
            againCancelList:[],
            loading:false,
            detailObj:{},
            role:{
                againCancel:[
                    { required: true, message: "请选择审批意见", trigger: "change" },
                ],
                reEndTime:[
                    { required: true, message: "请选择重办时间", trigger: "change" },
                ],
            },

            labelWidth: '120px',
        }
    },
    created(){
        this.getDetail()
    },
    mounted(){
        window.addEventListener("resize",(e) => {
            if(e.target.innerWidth < 1300){
                this.labelWidth = '80px'
            } else {
                this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
            }
            this.$forceUpdate()
        })
        if(window.innerWidth < 1300){
            this.labelWidth = '80px'
        } else {
            this.labelWidth = (120 / 1920 * window.innerWidth) + 'px'
        }
    },
    methods:{
        goBack(){
            this.$router.go(-1)
        },
        submitBtn(){
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.$api.timeoutInstructSave({
                    comment: this.form.commit,
                    againCancel:this.form.againCancel,
                    reEndTime:this.form.reEndTime,
                    timeoutId:this.detailObj.latestTimeoutRecord.id
                    }).then(res=>{
                        this.loading = false
                        if(res.data.code=='0'){
                            this.$message({
                                type: "success",
                                message: "保存成功",
                            });
                            this.goBack()
                        }else if(res.data.code == 3){
                            this.goBack()
                            this.$message.error(res.data.msg)
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    }).catch(error=>{
                        this.loading = false
                    })
                }
            })
        },
        // 获取详情
        getDetail(){
            this.$api.getTaskDetail(this.$route.query.id).then(res=>{
                if(res.data.code=='0'){
                    this.detailObj = res.data.data
                }else{
                    this.$message.error(res.data.msg)
                }
            })
             this.$api
              .getDictionary({ type: 'operate_again_cancel' })
              .then((res) => {
                  this.againCancelList = res.data.data
                // res.data.fieldName = item;
                // resolve(res.data);
              })
              .catch(() => {
                // resolve([]);
              });

        },

    },
    beforeDestroy(){
        window.removeEventListener("resize",(e) => {
            if(e.target.innerWidth < 1300){
                this.labelWidth = '80px'
            } else {
                this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
            }
            this.$forceUpdate()
        })
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
    background-color: #ffffff;
    height: calc(100% - 64px);
    padding: 34px 30px 30px 30px;
    .tableList {
        height: calc(100% - 100px);
    }
    .days{
        width: 100%;
        text-align: center;
        display: inline-block;
    }
}
</style>